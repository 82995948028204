import { crmCreateModalFormFn$ } from 'common-module/modal';

import { defaultModalSettings } from '../../utils/modal/modal';

import { SendEmailFormProvider } from './send-email-form.provider';
import { SendEmailInputData } from './send-email.data';
import { SendEmailProvider } from './send-email.provider';

export const openSendEmailModal = () => {
  const openModalForm = crmCreateModalFormFn$();

  return ({ title, data }: { title?: string; data: SendEmailInputData }) =>
    openModalForm({
      title: title ?? 'generic.sendMail',
      modalProvider: SendEmailProvider,
      formProvider: SendEmailFormProvider,
      data,
      settings: defaultModalSettings,
    });
};
