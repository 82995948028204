import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CrmActionButtonsConfig } from 'common-module/buttons';
import { CrmMessageService } from 'common-module/message';
import { Observable, of } from 'rxjs';

import { ModalFormProvider } from '~/crm/modal/modal-form.provider';

import { SendEmailFormProvider } from './send-email-form.provider';
import { SendEmailData, SendEmailInputData } from './send-email.data';

@Injectable()
export class SendEmailProvider extends ModalFormProvider<
  SendEmailData,
  SendEmailFormProvider
> {
  override formProvider = inject(SendEmailFormProvider);
  override data!: SendEmailInputData;

  private readonly messageService = inject(CrmMessageService);

  protected getFormProvider(): Observable<SendEmailFormProvider> {
    return of(this.formProvider);
  }

  protected override getActionsConfig(): Observable<CrmActionButtonsConfig> {
    this.saveTitle = this.data.saveTitle ?? 'generic.sendMail';
    return super.getActionsConfig();
  }

  protected override makeRequest() {
    return this.data.request(this.formProvider.form.value);
  }

  protected override onError(error: HttpErrorResponse) {
    if (error.status === 500) {
      this.messageService.error(`modal.message.checkEmail`);
    }
  }
}
