import { Injectable } from '@angular/core';
import { CrmFormConfigOptions } from 'common-module/form';
import { CrmFormFragment } from 'common-module/form/fragments';
import { Observable, of } from 'rxjs';

import { FormProvider } from '~/crm/form/form.provider';

import { AttachmentsComponent } from '../../components/attachments/attachments.component';
import { formDataInput, formDataText } from '../../defs/form/form-data-input';
import { requiredValidator } from '../../defs/form/form-validators';

import { SendEmailData, SendEmailInputData } from './send-email.data';

@Injectable()
export class SendEmailFormProvider extends FormProvider<
  SendEmailInputData,
  SendEmailData
> {
  protected override getFormData({
    data,
  }: CrmFormConfigOptions<SendEmailInputData>): Observable<SendEmailData> {
    return of({
      message: data?.message,
      recipient: data?.recipient,
      subject: data?.subject,
    });
  }

  protected override getFormFragments({
    data,
  }: CrmFormConfigOptions<SendEmailInputData>): Observable<CrmFormFragment[]> {
    let fragments: CrmFormFragment[] = [];

    if (!data?.recipientHidden) {
      fragments.push(
        formDataInput('recipient', {
          config: {
            label: 'generic.mail.recipient',
            validators: [requiredValidator],
          },
        })
      );
    }

    fragments = [
      ...fragments,
      formDataInput('subject', {
        config: {
          label: 'generic.mail.subject',
          validators: [requiredValidator],
        },
        options: {
          placeholder: 'generic.mail.yourMessage',
        },
      }),
      formDataText('message', {
        config: {
          label: 'generic.mail.message',
          validators: [requiredValidator],
        },
        options: {
          rows: 4,
        },
      }),
    ];

    if (data?.attachments?.length) {
      fragments.push({
        name: 'attachments',
        type: 'design',
        content: AttachmentsComponent,
        contentContext: {
          attachments: data.attachments,
        } as Partial<AttachmentsComponent>,
      });
    }

    return of(fragments);
  }
}
