import { NgForOf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzIconModule } from 'ng-zorro-antd/icon';

@Component({
  standalone: true,
  selector: 'app-attachments',
  template: `
    <div class="fs-12">{{ 'generic.attachments' | crmTranslate }}</div>
    <div class="mb-1" *ngFor="let a of attachments">
      <span nz-icon nzType="paper-clip" nzTheme="outline"></span> {{ a }}
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NzIconModule, NgForOf, CrmTranslatePipe],
})
export class AttachmentsComponent {
  @Input() attachments!: string[];
}
